@import '@lendoab/aphrodite/dist/tokens.scss';

.button {
    width: 100%;

    @media (min-width: map-get($breakpoints, upper-mobile)) {
        max-width: 300px;
    }
}

.tooltipArrow {
    z-index: 200;
}
