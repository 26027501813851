@import '@lendoab/aphrodite/dist/tokens';

.container {
    width: initial;
    @media (max-width: map-get($breakpoints, upper-tablet)) {
        width: 100%;
    }
}

.logo {
    aspect-ratio: 2 / 1;
    width: 96px;
}

.text {
    width: 96px;
    white-space: pre-line;
    word-break: break-word;
    text-transform: capitalize;
}

@mixin responsive-widths($mobile-width, $tablet-width, $desktop-width: null) {
    width: $mobile-width;

    @media (min-width: 768px) {
        width: $tablet-width;
    }

    @if $desktop-width != null {
        @media (min-width: 1024px) and (max-width: 1140px) {
            width: 100%;
            max-width: $desktop-width;
        }

        @media (min-width: 1141px) {
            width: 100%;
            width: $desktop-width;
        }
    }
}

.nstartBox {
    @include responsive-widths(343px, 736px);
}

.infoBox {
    @include responsive-widths(343px, 736px, 343px);
}

.nstartButton {
    width: 279px;

    @media (min-width: 768px) {
        width: 608px;
    }
}

.borderRadius {
    border-radius: 8px;
}

.nstartDivider {
    width: 100vw;
    max-width: 608px;

    @media screen and (max-width: 768px) {
        max-width: 279px;
    }
}
