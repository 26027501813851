@import '../../constants/styling_contstants.module.scss';

.overlay {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: index($zIndexPriorityElements, navigation) - 1;
    background-color: rgba(0, 0, 0, 0.5);

    &.open {
        display: block;
    }
}
