@import '@lendoab/aphrodite/dist/tokens';

.wrapper {
    min-height: 575px;
    // INFO: We can't do the same for 'height' as 'min-height' because if the parent height is too low it will break the layout.
    @media (min-width: map-get($breakpoints, desktop)) {
        min-height: 652px;
    }

    & > * {
        flex: 1 1 auto;
    }
}
