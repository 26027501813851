@import '../../constants/styling_contstants.module.scss';

.subNavMobile {
    position: fixed;
    right: 0;
    top: 56px;
    bottom: 0;
    z-index: index($zIndexPriorityElements, navigation);

    align-items: flex-start;
    background-color: #fefefe;
    transform: translateX(100%);
    transition: transform 200ms;
    width: 85%;

    @media (min-width: 768px) {
        top: 64px;
        width: 50%;
    }

    &.open {
        transform: translateX(0);
        box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.5);
    }

    .content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        .navList {
            flex: 1;
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .gradient {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 32px;
            background: linear-gradient(0deg, rgba(2, 0, 36, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
}

.subNavDesktop {
    position: fixed;
    max-width: 1136px;
    left: 0;
    right: 0;
    top: 64px;
    z-index: index($zIndexPriorityElements, navigation);
    transform: translateY(-100%);
    transition: transform 200ms;
    background-color: #fefefe;

    &.open {
        transform: translateY(0);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    }

    .content {
        & > div:not(:last-child) {
            border-right: 2px solid #d8d8d8;
        }
    }
}

.item:hover {
    text-decoration: underline;
}
