@import '@lendoab/aphrodite/dist/tokens.scss';

.listWrapper {
    list-style-position: inside;

    li {
        h5 {
            display: inline;
        }

        &::marker {
            color: map-get($colors, gray-30);
        }
    }
}
