@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/circular-pro/circular-pro-bold.woff2') format('woff2'),
        url('/fonts/circular-pro/circular-pro-bold.woff') format('woff'),
        url('/fonts/circular-pro/circular-pro-bold.eot?#iefix') format('embedded-opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/circular-pro/circular-pro-medium.woff2') format('woff2'),
        url('/fonts/circular-pro/circular-pro-medium.woff') format('woff'),
        url('/fonts/circular-pro-medium.eot?#iefix') format('embedded-opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/circular-pro/circular-pro-book.woff2') format('woff2'),
        url('/fonts/circular-pro/circular-pro-book.woff') format('woff'),
        url('/fonts/circular-pro/circular-pro-book.eot?#iefix') format('embedded-opentype');
    font-weight: 450;
    font-style: normal;
    font-display: swap;
}

/* ----- Font Family ----- */
*,
body,
html {
    font-family:
        'Circular Pro',
        Avenir,
        Adobe Heiti Std,
        Segoe UI,
        Trebuchet MS,
        sans‑serif;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    line-height: normal;
}

a {
    text-decoration: none;
    color: #1e70bf;
}

button,
input,
select {
    font-family:
        'Circular Pro',
        Avenir,
        Adobe Heiti Std,
        Segoe UI,
        Trebuchet MS,
        sans‑serif;
}

p,
h1,
h2,
h3,
h4,
h5 {
    font-family:
        'Circular Pro',
        Avenir,
        Adobe Heiti Std,
        Segoe UI,
        Trebuchet MS,
        sans‑serif;
    margin: 0;
}
