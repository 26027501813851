@import '@lendoab/aphrodite/dist/tokens';
@import '../../constants/styling_contstants.module.scss';

.footerImages {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    align-items: end;
    justify-content: center;

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(1fr, auto));
    }
}

.kundkraft {
    grid-column-start: 1;
    grid-column-end: span 2;

    @media (min-width: 1024px) {
        grid-column-start: 3;
        grid-column-end: 3;
    }
}

.columns {
    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 650px;
    }
}

.footerLogo {
    height: 20px;
    @media (min-width: map-get($breakpoints, tablet)) {
        height: 26px;
    }
}

.cookieWrapper {
    z-index: index($zIndexPriorityElements, banner);
}
