@import '@lendoab/aphrodite/dist/tokens';
@import '../constants/styling_contstants.module.scss';

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    padding-top: map-get($navbar, mobileHeight) * 1px;
    @media (min-width: map-get($breakpoints, tablet)) {
        padding-top: map-get($navbar, desktopHeight) * 1px;
    }
}
.containerLogin {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
