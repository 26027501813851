@import '../../constants/styling_contstants.module.scss';
@import '@lendoab/aphrodite/dist/tokens';

.nav {
    top: 0;
    left: 0;
    position: fixed;
    z-index: index($zIndexPriorityElements, navigation);
    min-height: map-get($navbar, mobileHeight) * 1px;

    @media (min-width: map-get($breakpoints, tablet)) {
        min-height: map-get($navbar, desktopHeight) * 1px;
        &.open {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
        }
    }
}

.hamburgerButton {
    width: 40px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;

    .hamburger {
        $space-factor: 2;
        margin-bottom: 4px;
        width: 36px;
        height: 25px;
        position: relative;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;

        span {
            display: block;
            position: absolute;
            height: 4px;
            width: 36px;
            background: #ffffff;
            border-radius: 1px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.15s ease-in-out;
        }

        &.cta {
            padding: 12px 24px;
            border-radius: 4px;
            background-color: #ffffff;
            color: #000000;

            svg {
                width: 20px;
                height: 20px;
                path {
                    fill: #000000;
                }
            }
        }

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: white;
            }
        }

        span:nth-child(1) {
            top: 0px;
            transform-origin: left center;
        }

        span:nth-child(2) {
            top: 10px;
            left: 6px;
            transform-origin: left center;
            width: 30px;
        }

        span:nth-child(3) {
            top: 20px;
            transform-origin: left center;
        }

        span:nth-child(3) {
            top: 20px;
            transform-origin: left center;
        }

        &.open span:nth-child(1) {
            transform: rotate(45deg);
            left: 8px;
            width: 28px;
        }

        &.open span:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        &.open span:nth-child(3) {
            transform: rotate(-45deg);
            left: 8px;
            width: 28px;
        }
    }
}
