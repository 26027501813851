@import '@lendoab/aphrodite/dist/tokens';

$STEP_BUBBLE_WIDTH: 20;

.indicatorContainer {
    flex-shrink: 0;
}

.indicatorBubble {
    line-height: 0;
    height: $STEP_BUBBLE_WIDTH * 1px;
    width: $STEP_BUBBLE_WIDTH * 1px;
    flex-shrink: 0;
}

.stepTail {
    top: $STEP_BUBBLE_WIDTH * 1px;
    height: 100%;
    width: 1px;
    background-color: map-get($colors, green-light);
    left: 50%;

    &::after {
        content: '';
        background: map-get($colors, blue-soft);
        height: 1px;
        transition: background 0.3s;
        width: calc(100% - #{$STEP_BUBBLE_WIDTH + 'px'});
    }
}

.passedStepTail {
    &::after {
        content: '';
        background: map-get($colors, green-light);
    }
}
