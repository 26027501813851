@import '@lendoab/aphrodite/dist/tokens';

.modalCloseButton {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 68px;
    width: 56px;
    background-color: transparent;
    border: none;
    padding: 0;

    & > svg {
        transform: rotate(45deg);
    }
}

.list {
    margin: 0;

    > li {
        color: map-get($colors, gray-10);
    }
}
