@import '@lendoab/aphrodite/dist/tokens.scss';

.root {
    min-width: 127px;
    min-height: 127px;

    @media (min-width: map-get($breakpoints, upper-tablet)) {
        min-width: 192px;
        min-height: 192px;
    }
}

.qrCode {
    width: 85px;
    height: 85px;

    @media (min-width: map-get($breakpoints, upper-tablet)) {
        width: 130px;
        height: 130px;
    }
}
